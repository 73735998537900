import {
  Alert,
  Box,
  Button,
  Container,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

//https://www.caricapets.co.uk/Dashboard/Account/CustomerLogin/07aaeae2-ddda-423e-a4a2-903d2352cdc3

export default function ContactUsForm() {
  const { control, handleSubmit, reset, register, formState } = useForm();
  const [captchaMessage, setCaptchaMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showBadAlert, setShowBadAlert] = useState(false);
  let captchaRef = useRef(undefined);

  const onSubmit = (_data, e) => {
    e.preventDefault();
    const captchaValue = captchaRef.current.getValue();

    if (!captchaValue) {
      setCaptchaMessage("reCAPTCHA is a required field");
    } else {
      emailjs
        .sendForm("service_l9n19rl", "template_dj9nxxm", e.target, {
          publicKey: "qiiFbByukk-y7fGG1",
        })
        .then(() => {
          reset(), setShowAlert(true);
        })
        .catch(() => {
          reset(), setShowBadAlert(true);
        });
    }
  };

  return (
    <Container>
      <Box pt={4}>
        <Typography
          variant="h5"
          style={{
            fontFamily: "Playfair Display",
            color: "#60695C",
            fontWeight: "bolder",
            marginBottom: 15,
          }}
        >
          General Enquiries
        </Typography>
        <Alert severity="info" sx={{ marginBottom: 2 }}>
          For bookings please{" "}
          <a href={""} target="_blank">
            book here
          </a>
          . Thankyou!
        </Alert>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <TextFieldElement
              control={control}
              name="name"
              label="Name"
              required
            />
            <TextFieldElement
              control={control}
              name="email"
              label="Email Address"
              required
            />
            <TextFieldElement
              control={control}
              name="number"
              label="Phone Number"
            />
            <TextFieldElement
              control={control}
              name="subject"
              label="Subject"
            />
            <TextFieldElement
              control={control}
              name="message"
              label="Your Message"
              required
              multiline
              minRows={5}
            />
            <ReCAPTCHA
              name="reCAPTCHA"
              ref={captchaRef}
              sitekey="6LdPJ2spAAAAAJOgpIqT41xX-HPlIuZ98wfCRHSd"
            />
            <Typography color="error">{captchaMessage}</Typography>
          </Stack>
          <Box>
            <Button
              sx={{
                marginTop: 2,
                marginBottom: 2,
                backgroundColor: "#4B88A2",
                marginRight: 5,
              }}
              type={"submit"}
              variant="contained"
            >
              Send Enquiry
            </Button>
            {showAlert && (
              <Alert severity="success" sx={{ width: 200 }}>
                Enquiry sent succesfully!
              </Alert>
            )}
            {showBadAlert && (
              <Alert severity="error">
                Something went wrong when submitting your enquiry. Please try
                again or contact us on: 0723836479836478
              </Alert>
            )}
          </Box>
        </form>
      </Box>
    </Container>
  );
}
