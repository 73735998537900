import { Box, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import CatPrintRight from "../images/singlePrintRight.png";
import CatPrintLeft from "../images/singlePrintLeft.png";
export default function WhatThisIncludes() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Typography
        textAlign="center"
        variant="h3"
        style={{
          fontFamily: "Playfair Display",
          color: "#667b68",
          margin: 10,
          paddingBottom: 50,
        }}
      >
        What this includes:
      </Typography>
      <Box
        sx={{
          borderRadius: "20px",
          backgroundColor: "#F5F7F3",
          padding: isMobile ? "10px" : "60px",
          marginBottom: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={CatPrintRight}
            alt="Green cat print"
            style={{ height: "60px", verticalAlign: "middle" }}
          />
          <Typography
            style={{
              paddingLeft: "20px",
              color: "#3e443b",
            }}
            variant="h5"
          >
            Cozy heated sleeping areas with a choice of comfy beds.
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={CatPrintLeft}
            alt="Green cat print"
            style={{ height: "60px", verticalAlign: "middle" }}
          />
          <Typography
            style={{
              paddingLeft: "20px",
              color: "#3e443b",
            }}
            variant="h5"
          >
            Popular wet/dry food varieties and lots of treats!
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={CatPrintRight}
            alt="Green cat print"
            style={{ height: "60px", verticalAlign: "middle" }}
          />
          <Typography
            style={{
              paddingLeft: "20px",
              color: "#3e443b",
            }}
            variant="h5"
          >
            Fun toys to keep our furry friends entertained.
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={CatPrintLeft}
            alt="Green cat print"
            style={{ height: "60px", verticalAlign: "middle" }}
          />
          <Typography
            style={{
              paddingLeft: "20px",
              color: "#3e443b",
            }}
            variant="h5"
          >
            Exercise area with scratching post and a sun shelf for toasty
            afternoon naps.
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={CatPrintRight}
            alt="Green cat print"
            style={{ height: "60px", verticalAlign: "middle" }}
          />
          <Typography
            style={{
              paddingLeft: "20px",
              color: "#3e443b",
            }}
            variant="h5"
          >
            Regular visits throughout the day.
          </Typography>
        </div>
      </Box>
    </Box>
  );
}
