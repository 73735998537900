import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Image from "mui-image";
import { Box, Dialog, DialogContent } from "@mui/material";
import { useState } from "react";

export default function StandardImageList() {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const images = require.context("../imageGallery", true);
  const imageList = images.keys().map((image) => images(image));

  const handleClickOpen = (image) => {
    setOpen(true);
    setCurrentImage(image);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ImageList variant="masonry" cols={3}>
        {imageList.map((image) => (
          <ImageListItem key={image}>
            <Box
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Image
                srcSet={`${image}?auto=format&dpr=2 2x`}
                src={`${image}?auto=format`}
                alt={image}
                loading="lazy"
                onClick={() => handleClickOpen(image)}
              />
            </Box>
          </ImageListItem>
        ))}
      </ImageList>
      <Dialog onClose={handleClose} open={open}>
        <DialogContent sx={{ p: 0 }}>
          <Image
            srcSet={`${currentImage}?auto=format&dpr=2 2x`}
            src={`${currentImage}?auto=format`}
            alt={currentImage}
            duration={1000}
            loading="lazy"
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
