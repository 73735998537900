import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import StandardImageList from "./components/ImageList";

export default function Gallery() {
  return (
    <div>
      <Typography
        textAlign="center"
        variant="h2"
        style={{
          fontFamily: "Playfair Display",
          color: "#60695C",
          padding: 50,
          backgroundColor: "#F7F1DE",
        }}
      >
        Gallery
      </Typography>
      <Container>
        <StandardImageList />
      </Container>
    </div>
  );
}
