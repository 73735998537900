import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CatLogo from "../images/catteryLogo2.png";
import { TimeSlotsTable } from "./TimeSlotsTable";

export default function Footer() {
  return (
    <Box
      sx={{
        position: "sticky",
        backgroundColor: "#A3B899",
        marginTop: 5,
      }}
    >
      <Box px={{ xs: 3, sm: 8 }} py={{ xs: 5, sm: 8 }}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Playfair Display",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Opening Times
                </Typography>
              </Box>
              <Box sx={{ marginTop: 1 }}>
                <TimeSlotsTable />
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Playfair Display",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Contact Us
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ marginTop: 1, color: "white" }}>
                  Telephone Number: 07377 587488
                </Typography>
                <Typography sx={{ marginTop: 1, color: "white" }}>
                  Email: info@newtonregiscattery.co.uk
                </Typography>
              </Box>
              <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                <Typography sx={{ color: "white" }}>
                  Address: <br />
                  Newton Regis Cattery, <br />
                  Four Winds Austrey Lane, <br />
                  Newton Regis, <br />
                  Tamworth, <br /> B79 ONR
                </Typography>
              </Box>
              <Typography variant="caption" sx={{ color: "white" }}>
                License Number: NWBC/2738/24
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Playfair Display",
                  color: "white",
                  fontWeight: "bold",
                  marginBottom: 1,
                }}
              >
                <br />
              </Typography>
              <img
                style={{ height: "auto", width: "100%" }}
                src={CatLogo}
                alt="Cattery Logo"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Typography
        sx={{
          textAlign: "center",
          color: "white",
          padding: 1,
        }}
      >
        © 2024 Newton Regis Cattery
      </Typography>
    </Box>
  );
}
