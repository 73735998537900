import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FrontSplash } from "./components/FrontSplash";
import {
  Button,
  Container,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import newtonRegisCattery from "./images/OutdoorPathPenView.JPG";
import Image from "mui-image";

export default function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box
        style={{
          backgroundColor: "#A3B899",
          paddingBottom: 5,
          display: "flex",
        }}
      >
        <Box
          textAlign="left"
          float="left"
          sx={{ display: "inline-block", textAlign: "left" }}
        >
          <Typography
            variant={isMobile ? "h3" : "h2"}
            style={{
              fontFamily: "Playfair Display",
              color: "#2A3C2B",
              marginLeft: 18,
              marginBottom: 25,
              alignItems: "center",
            }}
          >
            Book your cats next stay
            <Tooltip title="Click here to book a stay now!">
              <Button
                href={
                  "https://www.caricapets.co.uk/Dashboard/Account/CustomerLogin/07aaeae2-ddda-423e-a4a2-903d2352cdc3"
                }
                target="_blank"
                variant="outlined"
                sx={{
                  borderColor: "#2A3C2B",
                  color: "#2A3C2B",
                  "&:hover": {
                    borderColor: "#243425",
                    color: "#243425",
                    backgroundColor: "rgba(36, 52, 37, 0.10)",
                  },
                  marginLeft: 4,
                }}
              >
                Book here
              </Button>
            </Tooltip>
          </Typography>
        </Box>
      </Box>
      <Image
        src={newtonRegisCattery}
        style={{
          maxWidth: "100%",
          marginBottom: 10,
          maxHeight: "650px",
          objectPosition: "80% 20%",
        }}
      />
      <Container>
        <Box
          sx={{
            backgroundColor: "#FCFAF3",
            paddingBottom: 5,
          }}
        >
          <FrontSplash />
        </Box>
      </Container>
    </>
  );
}
