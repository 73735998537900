import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

export const TimeSlotsTable = () => {
  return (
    <TableContainer>
      <Table
        size="small"
        sx={{
          "& .MuiTableRow-root": {
            border: 0,
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              Monday
            </TableCell>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              10:00 - 12:00 <br /> 16:00 - 18:00
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              Tuesday
            </TableCell>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              10:00 - 12:00 <br /> 16:00 - 18:00
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              Wednesday
            </TableCell>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              CLOSED
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              Thursday
            </TableCell>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              10:00 - 12:00 <br />
              16:00 - 18:00
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              Friday
            </TableCell>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              10:00 - 12:00 <br /> 16:00 - 18:00
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              Saturday
            </TableCell>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              10:00 - 12:00 <br /> 16:00 - 18:00
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              Sunday
            </TableCell>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderColor: "#D4DDCD",
                  color: "white",
                },
              }}
            >
              CLOSED
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderBottom: 0,
                  color: "white",
                },
              }}
            >
              Bank Holidays
            </TableCell>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderBottom: 0,
                  color: "white",
                },
              }}
            >
              CLOSED
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
