import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, CardMedia } from "@mui/material";
import Grid from "@mui/material/Grid";
import Image from "mui-image";
import Owners from "./imageGalleryHD/Owners.jpg";
import OurCattery from "./imageGalleryHD/OurCattery.JPG";
import Map from "./images/Map.png";

export default function AboutUs() {
  return (
    <div>
      <Typography
        textAlign="center"
        variant="h2"
        style={{
          fontFamily: "Playfair Display",
          color: "#60695C",
          padding: 50,
          backgroundColor: "#F7F1DE",
        }}
      >
        About Us
      </Typography>
      <Container>
        <Grid container spacing={5} rowSpacing={5} mt={2}>
          <Grid item lg={6}>
            <Card elevation={0} sx={{ backgroundColor: "#FCFAF3" }}>
              <CardMedia>
                <Image src={Owners} style={{ m: "10px" }}></Image>
              </CardMedia>
              <CardContent>
                <Typography
                  textAlign="center"
                  variant="h3"
                  style={{
                    fontFamily: "Playfair Display",
                    color: "#667b68",
                    marginBottom: 10,
                  }}
                >
                  Meet the Owners
                </Typography>
                <Typography>
                  Linda and Steve Quigley have lived in Newton Regis for 27
                  years. They loved having cats whilst their children were
                  growing up and always dreamt of owning a cattery to take them
                  through their retirement years, caring for cats and meeting
                  new people. Linda is now Ofqual qualified and we are fully
                  licenced with NWBC.
                </Typography>
              </CardContent>
            </Card>
            <Grid item lg={12} mt={5}>
              <Card mt={10} elevation={0} sx={{ backgroundColor: "#FCFAF3" }}>
                <CardMedia>
                  <Image src={Map} style={{ m: "10px" }}></Image>
                </CardMedia>
                <CardContent>
                  <Typography
                    textAlign="center"
                    variant="h3"
                    style={{
                      fontFamily: "Playfair Display",
                      color: "#667b68",
                      marginBottom: 10,
                    }}
                  >
                    Find Us
                  </Typography>
                  <Typography>
                    Located just outside the beautiful countryside village of
                    Newton Regis on Austrey Lane between Newton Regis and
                    Austrey. We are the last house on the right (coming from
                    Newton Regis) before the motorway bridge. Click{" "}
                    <a
                      href="https://maps.app.goo.gl/5Acp8bEqyrTaTmGz5"
                      target="_blank"
                    >
                      here
                    </a>{" "}
                    for google maps location. If you have any trouble finding
                    us, give us a call, our contact details are below.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Card elevation={0} sx={{ backgroundColor: "#FCFAF3" }}>
              <CardMedia>
                <Image src={OurCattery}></Image>
              </CardMedia>
              <CardContent>
                <Typography
                  textAlign="center"
                  variant="h3"
                  style={{
                    fontFamily: "Playfair Display",
                    color: "#667b68",
                    marginBottom: 10,
                  }}
                >
                  Inside Our Cattery
                </Typography>
                <Typography>
                  Each of our 17 chalets consist of an insulated sleeping area
                  with thermostatically controlled heating where cats are given
                  a choice of two beds, one up high on a shelf, and the second
                  at floor level. Attached to this is a larger exercise area
                  with views to the garden and a shelf for afternoon naps. A
                  selection of our chalets can be joined together to accommodate
                  up to 4 cats from the same household.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
