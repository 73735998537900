import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PawPrintsCream from "../images/pawPrints.png";
import PawPrintsGreen from "../images/pawPrintsGreen.png";
import { useMediaQuery, useTheme } from "@mui/material";

export default function CatPricingBanner() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        marginTop: 5,
      }}
    >
      <Typography
        textAlign="center"
        variant="h3"
        style={{
          fontFamily: "Playfair Display",
          color: "#667b68",
          margin: 10,
          marginBottom: 40,
        }}
      >
        Our daily rates are
      </Typography>
      <Box sx={{ marginBottom: "50px" }}>
        <Container>
          <Grid
            textAlign="center"
            alignItems="center"
            sx={{ height: 450 }}
            container
          >
            <Grid
              p={2}
              sx={{ backgroundColor: "#D4DDCD", height: "100%" }}
              item
              xs={6}
              sm={3}
            >
              <Box>
                <Typography
                  variant="h4"
                  style={{
                    color: "#60695C",
                    margin: 10,
                  }}
                >
                  1 Cat
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant={!isMobile ? "h1" : "h2"}
                  sx={{
                    fontFamily: "Playfair Display",
                    color: "#60695C",
                    fontWeight: "bold",
                  }}
                >
                  £16
                </Typography>
              </Box>
              <hr style={{ border: "1px solid #A3B899" }}></hr>
              <Box>
                <Typography
                  variant="h6"
                  style={{
                    color: "#60695C",
                    margin: 10,
                  }}
                >
                  *Single Chalet (NOT SHARING)
                </Typography>
                <img
                  style={{ height: "50%", width: "50%" }}
                  src={PawPrintsCream}
                  alt="Cattery Logo"
                />
              </Box>
            </Grid>
            <Grid
              p={2}
              sx={{ backgroundColor: "#F1E8CA", height: "100%" }}
              item
              xs={6}
              sm={3}
            >
              <Box>
                <Typography
                  variant="h4"
                  style={{
                    color: "#60695C",
                    margin: 10,
                  }}
                >
                  2 Cats
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant={!isMobile ? "h1" : "h2"}
                  sx={{
                    fontFamily: "Playfair Display",
                    color: "#60695C",
                    fontWeight: "bold",
                  }}
                >
                  £24
                </Typography>
              </Box>
              <hr style={{ border: "1px solid #A3B899" }}></hr>
              <Box>
                <Typography
                  variant="h6"
                  style={{
                    color: "#60695C",
                    margin: 10,
                  }}
                >
                  *Single Chalet (SHARING)
                </Typography>
                <img
                  style={{ height: "50%", width: "50%" }}
                  src={PawPrintsGreen}
                  alt="Cattery Logo"
                />
              </Box>
            </Grid>
            <Grid
              p={2}
              sx={{
                backgroundColor: !isSmallMobile ? "#D4DDCD" : "#F1E8CA",
                height: "100%",
              }}
              item
              xs={6}
              sm={3}
            >
              <Box>
                <Typography
                  variant="h4"
                  style={{
                    color: "#60695C",
                    margin: 10,
                  }}
                >
                  2 Cats
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant={!isMobile ? "h1" : "h2"}
                  sx={{
                    fontFamily: "Playfair Display",
                    color: "#60695C",
                    fontWeight: "bold",
                  }}
                >
                  £32
                </Typography>
              </Box>
              <hr style={{ border: "1px solid #A3B899" }}></hr>
              <Box>
                <Typography
                  variant="h6"
                  style={{
                    color: "#60695C",
                    margin: 10,
                  }}
                >
                  *Two Chalets (NOT SHARING)
                </Typography>
                <img
                  style={{ height: "50%", width: "50%" }}
                  src={PawPrintsCream}
                  alt="Cattery Logo"
                />
              </Box>
            </Grid>
            <Grid
              p={2}
              sx={{
                backgroundColor: !isSmallMobile ? "#F1E8CA" : "#D4DDCD",
                height: "100%",
              }}
              item
              xs={6}
              sm={3}
            >
              <Box>
                <Typography
                  variant="h4"
                  style={{
                    color: "#60695C",
                    margin: 10,
                  }}
                >
                  3+ Cats
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant={!isMobile ? "h1" : "h2"}
                  sx={{
                    fontFamily: "Playfair Display",
                    color: "#60695C",
                    fontWeight: "bold",
                  }}
                >
                  £TBA
                </Typography>
              </Box>
              <hr style={{ border: "1px solid #A3B899" }}></hr>
              <Box>
                <Typography
                  variant="h6"
                  style={{
                    color: "#60695C",
                    margin: 10,
                  }}
                >
                  *Please contact us
                </Typography>
                <img
                  style={{ height: "50%", width: "50%" }}
                  src={PawPrintsGreen}
                  alt="Cattery Logo"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
