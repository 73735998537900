import { Box } from "@mui/material";
import CatPricingBanner from "./components/CatPricingBanner";
import WhatThisIncludes from "./components/WhatThisIncludes";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import HowToBook from "./components/HowToBook";
import CatPricingInformation from "./components/CatPricingInformation";

export default function Pricing() {
  return (
    <div>
      <Typography
        textAlign="center"
        variant="h2"
        style={{
          fontFamily: "Playfair Display",
          color: "#60695C",
          padding: 50,
          backgroundColor: "#F7F1DE",
        }}
      >
        Pricing
      </Typography>
      <Container>
        <Box display="flex" flexDirection="column">
          <CatPricingBanner />
          <CatPricingInformation />
          <WhatThisIncludes />
          <HowToBook />
        </Box>
      </Container>
    </div>
  );
}
