import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ContactUsForm from "./components/ContactUsForm";
export default function ContactUs() {
  return (
    <div>
      <Typography
        textAlign="center"
        variant="h2"
        style={{
          fontFamily: "Playfair Display",
          color: "#60695C",
          padding: 50,
          backgroundColor: "#F7F1DE",
        }}
      >
        Contact Us
      </Typography>
      <Container maxWidth="xl">
        <ContactUsForm />
      </Container>
    </div>
  );
}
