import * as React from "react";
import Home from "./Home";
import Pricing from "./Pricing";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Footer from "../src/components/Footer";
import { Link } from "react-router-dom";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useHistory,
} from "react-router-dom";
import { createTheme, Divider, IconButton, useMediaQuery } from "@mui/material";
import MenuComponent from "./components/MenuComponent";
import { ThemeProvider } from "@emotion/react";
import Gallery from "./Gallery";
import FacebookIcon from "@mui/icons-material/Facebook";

export default function App() {
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <AppBar
            position="sticky"
            style={{ backgroundColor: "#A3B899" }}
            elevation={0}
          >
            <Toolbar>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "Playfair Display",
                  fontWeight: "bolder",
                  color: "#2A3C2B",
                  textDecoration: "none",
                }}
                sx={{
                  flexGrow: 1,
                }}
                component={Link}
                to="/"
              >
                Newton Regis Cattery
              </Typography>
              {isMobile ? (
                <MenuComponent />
              ) : (
                <>
                  <Button
                    component={Link}
                    to="/"
                    color="inherit"
                    onClick={handleScrollToTop}
                  >
                    Home
                  </Button>
                  <Button
                    component={Link}
                    to="/about-us"
                    color="inherit"
                    onClick={handleScrollToTop}
                  >
                    About us
                  </Button>
                  <Button
                    component={Link}
                    to="/pricing"
                    color="inherit"
                    onClick={handleScrollToTop}
                  >
                    Pricing
                  </Button>
                  <Button
                    component={Link}
                    to="/gallery"
                    color="inherit"
                    onClick={handleScrollToTop}
                  >
                    Gallery
                  </Button>
                  <Button
                    component={Link}
                    to="/contact-us"
                    color="inherit"
                    onClick={handleScrollToTop}
                  >
                    Contact us
                  </Button>
                  <Divider
                    orientation="vertical"
                    flexItem
                    color="white"
                    sx={{ m: 1 }}
                  />
                  <IconButton
                    color="inherit"
                    target="_blank"
                    href="https://www.facebook.com/share/WjcTcAaPjvZNn5Cb/?mibextid=LQQJ4d"
                  >
                    <FacebookIcon backgroundColor="red" color="red" />
                  </IconButton>
                </>
              )}
            </Toolbar>
          </AppBar>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
    </>
  );
}

const handleScrollToTop = () => {
  window.scrollTo({ top: 0 });
};
